@import "~@iqmetrix/antd/dist/accessibility.css";
@import '~@iqmetrix/style-tokens/dist/scss/tokens-accessibility';

// The prefix to use on all css classes from ant.
$ant-prefix: ant;

$layout-padding-mobile: 0 $padding-base-small; //12px
$layout-padding-desktop: 0 $spacing-layout-xxlarge; //40px
$card-text-height: $spacing-layout-xxlarge; //40px
$export-button-margin: 25px;

// ANT ALERT
.#{$ant-prefix}-alert {
    margin: $margin-base-xsmall $margin-base-xsmall 0; //10px
}
// ANT LAYOUT
.#{$ant-prefix}-layout {
    min-height: 100vh;
    padding-bottom: $margin-base-small;
}

// ANT RESULT
.#{$ant-prefix}-result {
    padding: $padding-base-large 0;
}

// ANT CARD
.#{$ant-prefix}-card-body {
    padding: $spacing-base-base; //16px;
}

// ANT SPACE 
.#{$ant-prefix}-space {
    display: flex;
}

// ANT MENU
.#{$ant-prefix}-menu-horizontal:not(.#{$ant-prefix}-menu-dark) > .#{$ant-prefix}-menu-item {
    font-size: $font-size-base-heading; //16px
    margin: $layout-padding-desktop;
    @media only screen and (max-width: $width-screen-min-medium) {
        margin: $layout-padding-mobile;
    }
}

// ANT EMPTY
.#{$ant-prefix}-empty-normal {
    margin: $font-size-base-heading 0;
}

//ANT LIST
.#{$ant-prefix}-list-item {
    cursor: pointer;
}

// ANT ALERT
.#{$ant-prefix}-alert {
    margin: 0 0 $spacing-base-small; //12px
}

// ANT TAG
.#{$ant-prefix}-tag {
    margin: 0;
}

// ANT BUTTON
.#{$ant-prefix}-btn-link {
    white-space: normal;
    word-wrap: break-word;
    text-align: inherit;
}

// ANT DIVIDER
.#{$ant-prefix}-divider-horizontal {
    margin: $spacing-base-base 0; //16px
}

// ANT ROW
.#{$ant-prefix}-row {
  margin-bottom: $spacing-base-xsmall;
}

// CUSTOM STYLES
.remove-button {
    padding-left: 0;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.no-padding {
    padding: 0
}

.no-margin {
    margin: 0
}

.ghost-radio-buttons {
    width: 100%;
    display: flex;
    .#{$ant-prefix}-radio-button-wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
    }
}

.content-space {
    padding: $padding-base-small; //12px
}

.center-page-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.card-paragraph {
    @media only screen and (min-width: $width-screen-min-medium) {
        min-height: $card-text-height;
    }
}

.site-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $color-base-blue-base; //#0a76bd
    padding: $layout-padding-desktop;

    .app-header {
        color: $color-base-white-base;
        margin: 0;
    }

    .right-menu-item {
        a {
            color: $color-base-white-base;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .#{$ant-prefix}icon-menu {
        display: none;
    }

    .mobile-right-menu-item {
        display: none;

    }

    button.user-dropdown {
        padding: 0 0 0 $spacing-layout-xxlarge;
    }

    @media only screen and (max-width: $width-screen-min-large) {
        justify-content: center;
        padding: $layout-padding-mobile;

        .app-header {
            justify-self: flex-start;
            margin: auto;
        }

        .right-menu-item { 
            display: none;
        }
        .mobile-right-menu-item {
            display: flex;
            justify-content: right;
        }
        .#{$ant-prefix}icon-menu {
            display: inherit;
            color: $color-base-white-base;
            font-size: $font-size-base-display-small; //20px
            position: absolute;
            left: $padding-base-small;
        }
    }

}

.fixed-height {
    height: 300px;
    overflow-y: scroll;
}

.content-container {
    padding: $layout-padding-desktop;
    @media only screen and (max-width: $width-screen-min-medium) {
        padding: $layout-padding-mobile;
    }
}

.location-number-text {
    float: left;
}

.export-button {
    margin-top: $export-button-margin;
}

.desktop-margin {
    margin: $layout-padding-desktop;
}

.mobile-margin {
    margin: $layout-padding-mobile;
}

.app-header span.clickable:hover {
    cursor: pointer;
}

.signout-link {
    min-width:110px;
}
