@import "index.scss";

.#{$ant-prefix}-alert-info{
    background-color: $color-blue-200;
}

.#{$ant-prefix}-alert-success{
    background-color: $color-green-300;
    & .#{$ant-prefix}-btn-text{
        padding-left: 4px;
        span{
            font-weight: 700;
        }
    }
}

.#{$ant-prefix}-alert-info,
.#{$ant-prefix}-alert-success{
    
    & .#{$ant-prefix}-alert-icon{
        color: $color-blue-500;
        font-size: 24px;
    }

    & .#{$ant-prefix}-btn-text span{
        color: $color-black;
        font-weight: 400;
        text-decoration: underline;
    }
}